h1 {
    @apply font-bold text-4xl pt-2 mt-5
}

h2 {
    @apply font-bold text-2xl pt-2 mt-4
}

h3 {
    @apply font-bold text-xl pt-1 mt-3
}

h4 {
    @apply font-bold mt-2 pt-1
}